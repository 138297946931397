<template>

<div>
     <b-row  class="fixed_header">

       
        <b-col  sm="12" md="12" lg="12" xl="12" align="center">

            <img style="width: 118px;" src="/img/eqcoopblanco.svg"/>


        </b-col>
           
    </b-row>
    <b-row style="margin-top:70px">

        <b-col sm="12" md="12" lg="3" xl="3">

        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6" style="margin-top: 15px">

            <b-card style="padding: 15px;">

               
                    <p style="font-size:25px;font-weight:bold;text-align:center">Política de privacidad y condiciones de servicios</p>
            
    


    
                    <p style="font-size:16px;text-align:justify">

                        eqcoops.com es propiedad de EQCoop Systems, con domicilio en 5900 NW 97th Ave Suite C21 Doral, FL 33178 US y subsidiarias internacionales; es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección. <br><br>
                        <b>Su información personal será utilizada para proveer los servicios y productos que ha solicitado, informarle sobre cambios en los mismos y evaluar la calidad del servicio que le brindamos.</b><br><br>
                        Para las finalidades señaladas en el presente aviso de privacidad, podemos recabar sus datos personales de distintas formas: cuando usted nos los proporciona directamente; cuando visita nuestro sitio de Internet o utiliza nuestros servicios en línea, y cuando obtenemos información a través de otras fuentes que están permitidas por la ley. <br><br>
                        Recabamos sus datos personales de forma directa cuando usted mismo nos los proporciona por diversos medios, como cuando participa en nuestras promociones o nos da información con objeto de que le prestemos un servicio. <br><br>
                        Los datos que obtenemos por este medio pueden ser, entre otros: Su nombre, correo electrónico (email), país y ciudad de origen, páginas visitadas dentro de nuestro sitio, información para mejorar su experiencia en nuestro sitio como resolución de pantalla, explorador y tipo de sistema operativo utilizado. <br><br>
                        Usted tiene derecho de acceder, rectificar y cancelar sus datos personales, así como de oponerse al tratamiento de los mismos o revocar el consentimiento que para tal fin nos haya otorgado, a través de los procedimientos que hemos implementado. <br><br>
                        Para conocer dichos procedimientos, se puede poner en contacto en hola@eqcoops.com<br><br>
                        
                        Si usted desea dejar de recibir mensajes promocionales de nuestra parte puede solicitarlo a través de nuestro departamento de marketing cuyos datos se citan arriba (vía email o teléfono).<br><br>
                        
                        <b style="font-size:18px">Instrucciones para la eliminación de datos de usuario en EQCoop</b><br><br>

                        <p style="font-size:16px;text-align:justify">
                        
                        Si desea eliminar sus datos personales de EQCoop, siga las siguientes instrucciones:<br>
                        <br>
                        1.- Inicie sesión en su cuenta de EQCoop utilizando sus credenciales de inicio de sesión.
                        <br>
                        2.- Una vez que haya iniciado sesión, vaya a la sección de "Configuración" o "Perfil" en su cuenta. Esto puede estar ubicado en la parte superior o en el menú desplegable, dependiendo del diseño del sitio web.
                        <br>
                        3.- Dentro de la sección de "Configuración" o "Perfil", busque la opción que se refiere a la "Privacidad" o "Datos personales".
                        <br>
                        4.- En la sección de privacidad o datos personales, debería encontrar una opción para "Eliminar cuenta" o "Eliminar datos". Haga clic en esa opción.
                        <br>
                        5.- Es posible que se le solicite ingresar su contraseña o proporcionar alguna información adicional para confirmar su solicitud de eliminación de datos. Siga las instrucciones y proporcione la información requerida.
                        <br>
                        6.- Revise cuidadosamente los detalles proporcionados y confirme su solicitud para eliminar su cuenta y datos personales de EQCoop.
                        <br>
                        7.- Dependiendo de las políticas y procesos de EQCoop, es posible que se le envíe un correo electrónico de confirmación para verificar su solicitud. Siga las instrucciones en el correo electrónico, si corresponde.
                        <br>
                        8.- Una vez que haya completado el proceso de eliminación de datos, se eliminarán todos los datos personales asociados con su cuenta de EQCoop de manera permanente. Tenga en cuenta que esta acción no se puede deshacer y no podrá recuperar sus datos después de que se hayan eliminado.
                        <br>
                        <br>
                        Es importante tener en cuenta que, en algunos casos, EQCoop puede retener cierta información según lo requieran las leyes aplicables o para fines legales o comerciales legítimos.


                        <br>
                        <br>

                        Si tiene alguna dificultad o pregunta sobre el proceso de eliminación de datos, le recomendamos que se comunique con el servicio de atención al cliente de EQCoop para obtener asistencia adicional.
                        <br>

                        <br>
                        Recuerde que al eliminar su cuenta y datos personales, perderá acceso a todos los contenidos y servicios asociados con EQCoop, y no podrá recuperarlos en el futuro.


                      </p>
                        
                        <b style="font-size:18px">Aviso de Privacidad Detallado</b><br><br>



                      
  

                        En este aviso de privacidad se explica nuestra política respecto de la recolección, uso, divulgación y transmisión de los datos de usuarios por parte de la familia de empresas EQCoop Systems<br><br>
                        La presente política se podrá modificar a medida que actualizamos y ampliamos nuestros servicios. Por lo tanto, se recomienda consultarla periódicamente. <br><br>
                        Si accede a nuestro sitio web o de otro modo utiliza nuestro servicio, significa que presta su consentimiento a nuestras prácticas sobre información.<br><br>
                        <b style="font-size:18px"> Recolección y uso de la información</b><br><br>
                       
                        Nosotros recibimos y almacenamos información, incluida su información personal, acerca de usted y del uso que da a nuestro servicio. Esta información se recopila de diferentes maneras, entre ellas, cuando la introduce en nuestro sitio web y a partir de interacciones con nuestro servicio. <br><br>
                        La información personal es información que se puede usar para identificarlo o contactarse con usted. Información no personal significa toda información que no permita establecer una asociación directa con usted. Podemos recopilar, usar, transferir y divulgar información no personal para cualquier propósito. <br><br>
                        Usted no puede elegir no proporcionar información personal que le sea solicitada, pero en general, la mayoría de la información personal que solicitamos es necesaria para proporcionar nuestro servicio; la falta de tal información personal nos impedirá hacerlo.<br><br>
                        En algunas ocasiones, al interactuar con nosotros, le solicitaremos determinados datos personales a fin de poder prestarle, mejorar y personalizar nuestros servicios y campañas de marketing. Por el mismo motivo, podremos complementar la información personal que nos provea con la información de conocimiento público e información disponible de otras fuentes en la medida en que lo permita la ley. <br><br>
                        La información personal se utiliza, por ejemplo, a fin de procesar el proceso de registro, sus pedidos y sus pagos, y para comunicarnos con usted acerca de estos temas y otros. <br><br>
                        También utilizamos su email para enviarle mensajes acerca de las nuevas funciones de nuestros productos, las ofertas especiales, promociones, encuestas para clientes y otra correspondencia relativa a nuestro servicio. <br><br>
                        Si ya no desea recibir comunicaciones de marketing por email, simplemente póngase en contacto con nuestro Departamento de Protección de Datos Personales en hola@eqcoops.com indicando sus datos y el tipo de información que ya no desea recibir. Importante: no podrá dar de baja la Subscripción de cierta correspondencia que recibe de parte nuestra, tales como los mensajes relativos a las operaciones de su cuenta.<br><br>
                        Nosotros realizamos un seguimiento de todas sus interacciones con nosotros y registramos información relativa a usted y al uso que hace del servicio, que incluye, entre otras cosas, su actividad online, la selección, reseña y calificación de títulos, el historial de pagos y la correspondencia, así como también direcciones de protocolo de Internet, tipos de dispositivo, datos exclusivos del dispositivo, tales como identificador del dispositivo, sistemas operativo, las páginas que consultó, y toda la actividad relacionada.<br><br>
                        Utilizamos esta información para determinar el país donde se encuentra y así ofrecerle contenidos locales, para recomendarle páginas e información que consideramos que le pueden agradar, para personalizar el servicio a fin de que se adecue mejor a sus intereses personales, para ayudarnos a responder con mayor rapidez y eficiencia a las consultas y solicitudes y para mejorar o administrar mejor nuestra oferta de servicios, tanto para usted como para otros usuarios.<br><br>
                        Al utilizar algunos servicios de eqcoops.com tendrá la oportunidad de publicar información en foros públicos. Tenga en cuenta que cuando usted publica esta información, la información se hace de conocimiento público y ya no se encuentra protegida por esta política. No somos responsables por el uso por parte de terceros de la información que usted divulgue a través de nuestros servicios públicos.<br><br>
                        Nosotros nos reservamos el derecho de asociarnos con empresas cuyos productos consideramos pueden resultar de interés a nuestros clientes y prospectos. Junto con dichos socios, podremos enviarle anuncios promocionales y anuncios basados en la web en su nombre, en la medida en que lo permita la ley. <br><br>
                        Puede ponerse en contacto con nuestro Departamento de Protección de Datos Personales en hola@eqcoops.com. y solicitar que se excluya su nombre de los emails de marketing.<br><br>
                        <b style="font-size:18px">  Divulgación de la información</b><br><br>
                       
                        Es posible que compartamos su información con la familia de empresas de EQCoop Systems y con los asociados permanentes o eventuales. La familia de empresas que tiene acceso a su información seguirá prácticas uniformes con las descritas en esta Aviso de privacidad. <br><br>
                        Además, usamos otras empresas, agentes o contratistas para que realicen servicios en nuestro nombre. <br><br>
                        Por ejemplo, nos asociamos con otras empresas que nos ofrecen infraestructura que personalizan y mejoran nuestras páginas web, que procesan las operaciones con tarjeta de crédito, que brindan atención al cliente, cobran deudas, analizan y mejoran los datos, incluida la información relativa a la interacción de nuestros usuarios con nuestro sitio web, y procesan nuestras encuestas de consumo. <br><br>
                        En el transcurso de la prestación de estos servicios, estas empresas, y otras, pueden tener acceso a su información. Nosotros no autorizamos a estas empresas a utilizar ni divulgar su información personal, excepto con el fin de brindar el servicio que les solicitamos.<br><br>
                        Podemos ofrecer promociones o programas que requieran el intercambio de información con terceros como condición para participar. Por ejemplo, podemos asociarnos con empresas que ofrecen incentivos, tales como premios en millas por viajero frecuente cuando se suscribe o utiliza nuestro servicio. <br><br>
                        Al realizar este tipo de promociones, podremos compartir su nombre y otra información relacionada con la realización del incentivo. Al participar en dichas promociones o programas, implícitamente acepta que compartamos su información. Importante: nosotros no controlamos las prácticas de privacidad de estas empresas terceras.<br><br>
                        EQCoop Systems también se reserva el derecho de divulgar información personal cuando razonablemente consideramos que el acceso, el uso, la preservación o la divulgación de tal información es necesaria para (a) cumplir con cualquier legislación, reglamentación, proceso legal o solicitud gubernamental correspondiente, (b) hacer cumplir términos de uso correspondientes, incluida la investigación de posibles infracciones de ellos, (c) detectar, evitar o abordar actividades ilegales o presuntamente ilegales y cuestiones técnicas o de seguridad, o (d) proteger de daño contra los derechos, la propiedad o la seguridad de EQCoop Systems, sus usuarios o el público, según lo requiera o permita la ley. En relación con una reorganización, restructuración, fusión o venta, o cualquier otra transferencia de bienes, nos reservamos el derecho de transferir información, incluida la información personal, a condición de que el destinatario se comprometa a respetar su información personal de conformidad con nuestra Aviso de privacidad.<br><br>
                        <b style="font-size:18px">   Facebook</b><br><br>
                        En el caso de algunos miembros ubicados en determinados países, si usted conecta su cuenta de alguno de los productos del grupo de empresas de EQCoop Systems con Facebook, podremos acceder de manera continua a su información y a la información de sus amigos registrados en Facebook que también tengan una cuenta de Facebook conectada con EQCoop Systems, a menos que se desconecte. Importaremos, utilizaremos, divulgaremos y conservaremos esta información a fin de personalizar y mejorar el servicio de EQCoop Systems para usted, para sus amigos y para otras personas, entre otras cosas. <br><br>
                        Además, cuando conecte su cuenta de EQCoop Systems con Facebook, podremos publicar automáticamente su actividad, incluido lo que vio. <br><br>
                        Sus amigos y todos los que tengan acceso a su información de Facebook verán que es miembro de EQCoop Systems así como también cualquier otra información acerca del uso del servicio de EQCoop Systems que se publique, tanto en Facebook como en EQCoop Systems<br><br>
                        También podrá obtener acceso a información similar acerca de sus amigos de Facebook que están conectados con EQCoop Systems Importante: Facebook importará, utilizará y conservará esta información. Consulte la Aviso de privacidad de Facebook para obtener mayor información, incluida la forma en que manejan la información. Puede controlar cómo compartir determinada información mediante la Configuración de parámetros de seguridad de Facebook. <br><br>
                        Nosotros y Facebook actualizamos y mejoramos esta función de manera continua, por lo que es posible que la información y las funciones disponibles cambien ocasionalmente.<br><br> 
                        Estos cambios pueden ocurrir sin aviso y es posible que no estén descritos aquí ni en los Términos de uso.<br><br>
                        <b style="font-size:18px">   Cookies y otras tecnologías</b><br><br>
                       
                        Cuando usted interactúa con nuestro servicio, nosotros y nuestros proveedores podemos colocar cookies en su sistema. Las cookies son pequeñas unidades de datos que se envían a su navegador desde un servidor web y se almacenan en su sistema a los efectos de registrar datos. Por lo general, las cookies pueden hacer que Internet sea más útil, ya que almacenan información acerca de sus preferencias en un sistema en particular.<br><br>
                        Además de las cookies, nosotros y nuestros proveedores de servicio utilizamos una amplia gama de otras tecnologías, tales como etiquetas de pixeles (contadores de visitantes), imágenes en formato .GIF transparentes y otras tecnologías que forman parte de nuestro software de aplicación o de su dispositivo, para registrar información acerca de cómo interactúan los usuarios con nuestro servicio. <br><br>
                        Estas cookies y otras tecnologías pueden ayudar a personalizar el servicio para usted y ayudar a asociar sus interacciones con nuestro servicio a otra información dentro de su cuenta. Nos pueden mostrar, por ejemplo, páginas populares, tasas de conversión, patrones de visualización, registro de clics y otra información que puede resultar útil para la mejora, el funcionamiento y la supervisión de nuestro sitio web y de otros aspectos de nuestro servicio.<br><br>
                        La sección de Ayuda de la barra de herramientas de la mayoría de los navegadores le indicará cómo evitar que su navegador acepte nuevas cookies, cómo hacer que el navegador le avise cuando reciba una nueva cookie, o cómo desactivar las cookies por completo. Si su navegador está configurado para no aceptar cookies, no podrá recibir la experiencia completa al utilizar el servicio ni el sitio web de EQCoop Systems<br><br>
                        Nosotros recurrimos a otros terceros, agencias de publicidad, para que presenten nuestros anuncios. Estas empresas pueden utilizar información acerca de sus visitas a este sitio web y a otros a fin de mostrarle anuncios acerca de bienes y servicios que le pueden resultar de interés.<br><br>
                        <b style="font-size:18px">   Seguridad</b><br><br>
                        
                        EQCoop Systems toma muy en serio la seguridad de la información y utiliza medidas administrativas, técnicas, físicas y de gestión razonables para proteger su información personal del acceso no autorizado. Por ejemplo, utilizamos Secure Sockets Layering (SSL) -un protocolo estándar en la industria- en algunas de las transmisiones que nos envía, a fin de cifrar cierta información personal que transmite durante el proceso de registro y de Subscripción. <br><br>
                        Además, la plataforma eqcoops.com está avala da por PCI-DSS.<br><br>
                        Lamentablemente, ningún sistema de seguridad es 100% seguro. Por lo tanto, no podemos garantizar la seguridad de su información y no podemos asumir la responsabilidad ante un acceso inapropiado. <br><br>
                        Al utilizar nuestro servicio, incluido nuestro sitio web y nuestras interfaces de usuario, o al transmitirnos información por cualquier medio, usted da su consentimiento para que nos comuniquemos con usted en forma electrónica respecto de cuestiones de seguridad, privacidad y administrativas en relación con el uso que hace del servicio de EQCoop Systems<br><br>
                        Es su responsabilidad proteger la confidencialidad de la información de acceso a su cuenta y restringir el acceso a la computadora o dispositivo mediante el cual accede a su cuenta. Si revela su contraseña a cualquier persona o comparte su cuenta o dispositivos con otras personas, debe asumir plena responsabilidad por sus actos. Siempre que sea posible, los usuarios de dispositivos públicos o compartidos deben cerrar sesión al finalizar cada visita. <br><br>
                        Si vende o devuelve una computadora o un dispositivo que usted utilizaba para ingresar a sus servicios, debe cerrar sesión y desactivar su cuenta antes de hacerlo. Si no cierra sesión o no desactiva su cuenta, los usuarios posteriores podrán acceder a la información de su cuenta.<br><br>
                        <b style="font-size:18px">    Cambios a la política</b><br><br>
                       
                        La presente política se podrá modificar a medida que actualizamos y ampliamos nuestros servicios. Consulte regularmente si existe alguna actualización a esta política. Usted acuerda que su aceptación de los Términos de Uso y el Aviso de privacidad posterior a su consentimiento original, así como el uso del sitio web de EQCoop Systems o el uso continuo de nuestro servicio después de publicar un cambio a esta política implica que acepta dichos cambios y se obliga a respetarlos. <br><br>
                        Podrá acceder a la última versión de esta Aviso de privacidad visitando nuestro sitio web y haciendo clic en "Aviso de privacidad" al pie de las páginas del sitio web de EQCoop Systems <br><br>
                        La última versión del presente Aviso de privacidad prevalecerá sobre todas las versiones anteriores.<br><br>
                        <b style="font-size:18px">    Transmisión de la información y usuarios internacionales</b><br><br>
                       
                        Como Internet es un entorno global, el uso de Internet para recopilar y procesar información implica por necesidad la transmisión de datos de manera internacional. Por lo tanto, al usar EQCoop Systems, acuerda y acepta que la transmisión de su información fuera de su país de residencia a cualquier país (incluidos los Estados Unidos) donde tengamos instalaciones o actividades con terceros (incluidos, entre otras cosas, procesadores de pagos, proveedores de servicios de nube u otros servicios de TI, y otras empresas que nos provean servicios). Usted entiende que los países a los que transmitimos información pueden tener un nivel de protección de datos menos integral que el de su país.<br><br>
                        <b style="font-size:18px">    Cómo contactarnos</b><br><br>
                        
                        Si tiene alguna pregunta respecto de su cuenta, este Aviso de privacidad, incluido el tratamiento de información personal y/o nuestro uso de las cookies y otras tecnologías, contáctenos a  hola@eqcoops.com<br><br>
                        
    
                    </p>
    
 

            </b-card>

</b-col>

<b-col sm="12" md="12" lg="3" xl="3">

</b-col>
  


         

    </b-row>

    <b-row  class="fixed_footer">

        <b-col
            style="color:white !important;"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="text-center align-items-center justify-content-xl-between"
          >
   
        

            <a
              target="_blank"
              title="Facebook de EQCoop"
              href="https://www.facebook.com/eqpay.oficial"
            >   <feather-icon
             icon="FacebookIcon"
             size="25"
             class="redesWhite"
             /></a>
            <a
              target="_blank"
              title="Instagram de EQCoop"
              href="https://www.instagram.com/eqpay.oficial"
            > <feather-icon
             icon="InstagramIcon"
             size="25"
             class="redesWhite"
             /></a>
            <a
              target="_blank"
              title="Email de EQCoop"
              href="mailto:hola@eqcoops.com?Subject=Tengo%20una%20pregunta"
            > <feather-icon
             icon="MailIcon"
             size="25"
             class="redesWhite"
             /></a>
            <a
              target="_blank"
              title="Tiktok de EQCoop"
              href="https://www.tiktok.com/@eqpays?_t=8WRpV7M3v65&_r=1"
            > <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#fff"
              widht="10"
              height="22"
              viewBox="0 0 448 512"
            ><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg></a>

            <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;">  <a
                class="color_a"
              href="#"
              target="_blank"
            >Términos y condiciones</a> - <b-link
            class="color_a"
              :to="{ name: 'privacidad'}"
              target="_blank"
            >Política de privacidad y condiciones de servicios</b-link> -  <a
            class="color_a"
              href="#"
              target="_blank"
            >Reclamos</a></p>

            <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;"> Copyright &copy; {{ year }} <a
              href="https://web.eqcoops.com"
              target="_blank"
              class="color_a"
            >- EQCoop</a></p>

          </b-col>


    </b-row>

</div>
  </template>
  
  <script>
  /* eslint-disable global-require */
  import { BRow, BCol, BButton, BCard, BLink } from 'bootstrap-vue'

  
  export default {
    components: {
        BRow, BCol, BButton, BCard, BLink
    },
    data() {

     
      return {
        year: new Date().getFullYear(),
      }
    },
    computed: {

    },mounted(){


     
        document.title = 'EQCoop - Política de privacidad y condiciones de servicios'
    },
    methods: {


    }
  }
  </script>
  
  <style lang="scss">




  </style>
  